import { ChatIcon, CodeIcon, EyeIcon } from "@heroicons/react/outline";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlurbSphere from "~components/features/BlurbSphere";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import ProvenExpert from "~components/features/ProvenExpert";
import Layout from "~components/layouts/Layout";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import CtaSection from "~components/sections/Cta";
import PageHeader from "~components/sections/PageHeader";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import Header from "~components/sections/landingpages/v2/Header";
import About from "~components/sections/landingpages/About";
import ReviewSlider from "~components/features/ReviewSlider";

const keyword = "SEO Agentur Kassel";
const title = "SEO Agentur Kassel: mehr Kundenanfragen ↗️ ohne Werbung";
const description =
	"SEO Agentur Kassel | Die Lösung für mehr Kundenanfragen ohne Werbung für erfolgreiche Internetseiten ☎️ 0156 783 723 78 ▻ Beratung & Angebot!";

export default function Page({ data, location }) {
	const isSSR = typeof window === "undefined";
	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<Header
				schema={{
					name: keyword,
					image: data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src,
					description: description,
					offerType: "aggregateOffer",
					price: "500",
					priceTo: "5000",
					url: `${data.site.siteMetadata.siteUrl}${location.pathname}`,
				}}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
				images={{
					image: {
						data: data.imageHeaderAndreas,
						alt: "SEO Agentur Kassel: Andreas Lautenschlager im Portrait",
						className: "max-w-sm",
						classNameDiv: "max-w-sm",
					},
					imageBackground: {
						data: data.imageHeaderSearchConsole,
						alt: "SEO Agentur Kassel: Search Console Daten",
						className: "max-w-2xl mt-8",
					},
				}}
				benefits={["Höchste Ansprüche", "Optimierung", "Inhaltserstellung", "Backlinkaufbau"]}
			>
				<>
					<h1 className="mb-5 font-display text-2xl font-bold md:text-4xl">
						SEO Agentur Kassel: mehr Kundenanfragen ohne Werbung
					</h1>
					<P className="mb-8">
						Die <strong>SEO Agentur Kassel</strong>, Lautenschlager Marketing & Entwicklung, hat die Lösung für mehr
						Kundenanfragen ohne Werbung. Über 95 Prozent aller Menschen, die im Internet nach einer Dienstleistung
						suchen, schauen sich nur die Ergebnisse auf der ersten Seite von Google an. Wie Du mit SEO auf diese
						begehrte erste Seite kommst, zeigen wir Dir gerne anhand unserer kostenlosen Erstberatung.
					</P>

					<Button
						text="Termin vereinbaren"
						href="https://termin.lautenschlager.de/"
						target="_blank"
						rel="noopener noreferrer"
						className="mb-3 mr-3"
						brand
					/>
					<Button text="Anfragen" to={`${location.pathname}anfragen/`} />
				</>
			</Header>
			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Kostentransparenz" number="01" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Ist SEO lohnenswert?
				</H2>
				<Grid cols={2}>
					<div>
						<H3 display className="mb-5 font-display">
							Return on Invest
						</H3>
						<P className="mb-3">
							Im Suchmaschinen-Marketing muss man bedacht vorgehen, weil SEO ist kein Erfolg über Nacht. Oft dauert es{" "}
							<strong>sechs bis zwölf Monate</strong>, bis eine Website wirklich in den Rankings auftaucht und Deine
							Website mehr Traffic bekommt. Das macht es schwierig für Unternehmen zu spüren, ob sich SEO lohnt, da wir
							die Veränderungen nicht über Nacht sehen können. Wenn man jedoch berücksichtigt, wie lange SEO dauert und
							wie stabil diese Rankings sind, wird klar, dass sich diese Investition in Bezug auf die
							Kundenakquisitionskosten auszahlt. SEO kann auch über viele Monate oder Jahre hinweg Leads und Sales
							liefern.
						</P>
						<P className="mb-3">
							<strong>Eine kleine Beispielrechnung:</strong>
						</P>
						<P className="mb-3">
							Nehmen wir mal an, dass eine Optimierung der Seite etwa 1000,- € gekostet hat. Das Keyword hat einen
							Traffic von ca. 500 Suchen pro Monat. Bei einer Konversionsrate von ca. 1 % und einem Kundenumsatz von
							300,- € pro Anfrage macht Dein Unternehmen einen Umsatz von 1500,- € pro Monat für das eine Keywords.
						</P>
						<P className="mb-3">
							<span className="font-bold">
								Das bedeutet bei einem einmaligen Einsatz von 1000,- € verdienst Du 1500,- € pro Monat.
							</span>{" "}
							Du hast ab dem ersten Monat ein positives Return on Invest, sobald Deine Seite bei Google auf der ersten
							Seite rankt.
						</P>
						<P className="mb-3">
							<strong>Ein zweites schwierigeres Beispiel:</strong>
						</P>
						<P className="mb-3">
							Nehmen wir mal an, dass eine Optimierung der Seite etwa 3000,- € gekostet hat. Das Keyword hat einen
							Traffic von ca. 1000 Suchen pro Monat. Bei einer Konversionsrate von ca. 1 % und einem Kundenumsatz von
							50,- € pro Anfrage machst Du einen Umsatz von 500,- € pro Monat.
						</P>
						<P className="mb-3">
							<span className="font-bold">
								Das bedeutet bei einem einmaligen Einsatz von 3000,- € verdienst Du 500,- € pro Monat.
							</span>{" "}
							Du hast ab dem sechsten Monat ein positives Return on Invest, sobald Deine Seite bei Google auf der ersten
							Seite rankt.
						</P>
						<P>
							Fazit: Je nach Keyword, Optimierungskosten, Konversionsrate, Marketing-Strategie und Umsatz pro Auftrag,
							kann sich SEO nach kürzester Zeit lohnen, oder erst nach einigen Monaten. In jedem Fall lohnt sich jedoch
							die Optimierung,{" "}
							<span className="font-bold">
								da die Portionierung in den Suchmaschinen für viele Monate, oder sogar Jahre bestehen bleibt
							</span>
							. Solltest Du in den Rankings wieder nach hinten fallen, so haben wir das im Blick und helfen Dir wieder
							nach vorne. Wir finden, dass SEO eine perfekte Ergänzung zu Deinem Marketing sein kann.
						</P>
					</div>
					<div>
						<div className="bg-pattern my-3 p-6">
							<GatsbyImage
								image={getImage(data.imageSeoVergleich)}
								alt="SEO Agentur Kassel Preise | SEO vs klassische Werbekampagne"
								title="SEO Agentur Kassel Preise | SEO vs klassische Werbekampagne"
							/>
						</div>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2}>
					<div>
						<H3 display className="mb-5 font-display">
							Was kostet eine Optimierung bei der SEO Agentur Kassel?
						</H3>
						<P className="mb-3">
							Die Optimierung einer Website zu einem bestimmten Keyword schwankt extrem. Das liegt einfach daran, dass
							ein Keyword je nach Wettbewerb und Suchvolumen stärker oder schwächer umkämpft ist. In Kassel oder anderen
							Städten kann ein Keyword günstiger oder teurer sein, je nach Sprachgebrauch der potenziellen Kunden. Wie
							schwieriger die Umgebung in diesem Fall ist, umso schwieriger ist unser Job und der Aufwand unserer
							Arbeit.
						</P>
						<P className="mb-3">
							Möchtest Du nun ungefähr wissen, wie hoch der Aufwand einer Optimierung ist, kannst Du das Tool „
							<a href="https://howmuchtorank.com/de/" target="_blank" className="underline" rel="noreferrer">
								How much to rank
							</a>
							“ in Betracht ziehen. Dieses Tool dient auch uns, als grobe Richtlinie, um einzuschätzen, wie hoch unser
							Aufwand wäre. Im groben kann man sagen, dass eine Optimierung in Kassel für ein Keyword mit mittlerem
							Suchvolumen um die 1.000,- € kosten kann.
						</P>
						<P className="mb-3">
							Die Technik einer Website ist ebenfalls für die Marketing-Strategie entscheidend. Damit wir auch richtig
							optimieren können und so die besten Erfolge erbringen können, benötigen wir gute und schnelle Websites als
							Basis. Da hier auch Zusatzkosten auf Dich zukommen und uns der Service-Aspekt bedeutungsvoll ist, bieten
							wir passend zu unserem SEO auch technisch einwandfreie Websites passend als{" "}
							<Link to="/leistungen/website-abo/" className="underline">
								Website Abo an
							</Link>
							. So müssen wir Dir nicht wegen jeder Kleinigkeit eine Rechnung schreiben und Du zahlst einen monatlichen
							Fixbetrag und wir kümmern uns um den Rest. Wir gehen da als Agentur andere Wege als die üblichen anderen
							Unternehmen und das soll uns besonders machen.
						</P>
					</div>
					<div>
						<div className="bg-pattern my-3 p-6">
							<StaticImage
								src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_keyword-kosten.png"
								alt="SEO Agentur Kassel Preise | Kosten für ein Keyword"
								title="SEO Agentur Kassel Preise | Kosten für ein Keyword"
							/>
						</div>
					</div>
				</Grid>
			</Container>
			<Container id="konzept">
				<Grid cols={2} className="mb-8 md:mb-32" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Von A bis Z" number="02" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Wie wir als SEO Agentur Kassel helfen können
						</H2>
						<P className="mb-3">
							Suchmaschinenoptimierung, kurz SEO, bezeichnet den Prozess, Änderungen an Deiner Webseite vorzunehmen, um
							sie für Suchmaschinen leichter auffindbar zu machen und zu ranken. Aber auch äußere Faktoren wie externe
							Verlinkungen auf Deine Website sind wichtige Kennzahlen.
						</P>
						<P className="mb-3">
							Wir, die SEO Agentur Kassel, Lautenschlager Marketing & Entwicklung, haben uns mit unserem Partner der{" "}
							<a href="https://seo-premium-agentur.de" target="_blank" rel="dofollow noreferrer" className="underline">
								SEO Premium Agentur
							</a>{" "}
							genau darauf spezialisiert. Die Symbiose aus exzellenter Websites und das Marketinggeschick von Oliver
							führt zu tollen Ergebnissen, die bestimmte Suchwörter auf die erste Seite katapultieren können. Wir
							analysieren Dein Unternehmen aus verschiedenen Perspektiven und entwickeln auf dieser Grundlage
							maßgeschneiderte Lösungen, die speziell auf Dich zugeschnitten sind.
						</P>
						<P className="mb-3">
							Wenn Du daran interessiert bist, Dein Ranking in den Google-Suchergebnissen in der Nähe in Kassel zu
							verbessern, dann solltest Du uns kontaktieren.
						</P>

						<ReviewSlider />
					</div>
					<div className="bg-pattern p-3">
						<StaticImage
							src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_wie-wir-dir-helfen-koennen.jpg"
							alt="SEO Agentur Kassel Konzept | Frau am Tablet mit Google geöffnet"
							title="SEO Agentur Kassel Konzept | Frau am Tablet mit Google geöffnet"
							aspectRatio={16 / 9}
						/>
					</div>
				</Grid>
				<div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
					<BlurbSphere
						number="1"
						title="Kostenlose Erstanalyse"
						text="Im ersten Schritt werden wir Deinen aktuelle Sichtbarkeit prüfen und beraten Dich gerne kostenfrei, wie Du Deine online Sichtbarkeit mit SEO verbessern kannst."
						svg={<ChatIcon />}
					/>
					<BlurbSphere
						number="2"
						title="Suchmaschinenoptimierung"
						text="Wir optimieren Deine Website sowohl Onpage als auch Offpage. Dazu benötigen wir Deine aktuellen Logindaten für Deine Website, oder bauen Dir gerne auch eine neue optimierte Website."
						svg={<CodeIcon />}
					/>
					<BlurbSphere
						number="3"
						title="Analyse"
						text="Eine dauerhafte SEO-Betreuung ist ein Wechselspiel aus Optimierung und Analyse. Wir analysieren unsere Änderungen und beobachten, wie die Suchmaschinen darauf reagieren und notieren uns weitere Vorgehensweise."
						svg={<EyeIcon />}
					/>
				</div>
			</Container>
			{/* <CtaSection
				title="SEO anfragen"
				text="Du bist überzeugt? Dann Fülle stelle uns gerne eine unverbindliche Anfrage und wir evaluieren wie wir Dir helfen können."
				button="SEO anfragen"
				onClick={() => navigate("/leistungen/seo-agentur-kassel/anfragen/")}
			/> */}
			{/* <Container id="technik">
				<Grid cols={2} className="mb-16" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Technik und Content in Symbiose" number="03" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Wie funktioniert die Onpage Suchmaschinenoptimierung?
						</H2>
						<P className="mb-3">
							Eine Onpage Optimierung ist nicht immer ganz einfach. Es beginnt meistens schon mit der Konzeption und dem
							Ziel Deiner Website. Möchtest Du Marketing in Kassel machen oder in einer anderen Stadt? Ist die
							Suchmaschinenoptimierung der richtige erste Schritt Dein Unternehmen? Welche Maßnahmen müssen sonst noch
							getroffen werden? Wenn Du Dich für die Suchmaschinenoptimierung entschieden hast, gibt es bestimmte
							Grundregeln, die immer greifen. Folgende Punkte gehen wir bei einer Onpage Optimierung immer an:
						</P>
						<ul className="space-y-3 list-disc list-inside">
							<li className="list-item">
								Wir bauen Deine Webseite mit den spezifischen Keywords, die Du anvisierst, im Hinterkopf.
							</li>
							<li className="list-item">
								Mit einer Keyword-Recherche rund um Kassel und Umgebung (oder einer anderen Stadt) prüfen wir, welche
								Stichwörter für Dein Unternehmen relevant sind.
							</li>
							<li className="list-item">
								Bei den Titeln und Beschreibungen verwenden wir relevante und beschreibende Wörter.
							</li>
							<li className="list-item">Wir erstellen passende Inhalte auf Deiner Website.</li>
						</ul>
					</div>
					<div className="p-3 bg-pattern">
						<StaticImage
							src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_onpage-optimierung.jpg"
							alt="SEO Agentur Kassel OnPage | Bildschirm mit Lighthouse Test"
							title="SEO Agentur Kassel OnPage | Bildschirm mit Lighthouse Test"
							placeholder="blurred"
							aspectRatio={16 / 12}
							transformOptions={{ cropFocus: "south" }}
						/>
					</div>
				</Grid>
			</Container> */}
			{/* <Container>
				<Grid cols={2} itemsCenter>
					<div className="md:order-2">
						<Divider className="mb-4" />
						<NumberedSection text="Was suchen die Leute?" number="04" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Was sind die richtigen Keywords?
						</H2>
						<P className="mb-3">Ein gutes Keyword erfüllt die folgenden drei Anforderungen:</P>
						<ul className="mb-3 space-y-3 list-decimal list-inside">
							<li className="list-item">Es beschreibt den Inhalt der Webseite so genau wie möglich.</li>
							<li className="list-item">
								Es verwendet dieselben Wörter, die die potenziellen Kunden (in Kassel und Umgebung) umgangssprachlich in
								den Suchmaschinen wie Google verwenden, wenn sie nach den Informationen suchen.
							</li>
							<li className="list-item">
								Ein gutes Keyword ist nicht unbedingt das meistgenutzte Keyword. Es kann auch besser ein Nischenkeyword
								sein, das aus mehreren Wörtern (Phrase) besteht. Als SEO Agentur überlegen wir uns dann entsprechende
								Maßnahmen und Marketing-Strategie.
							</li>
						</ul>
						<P>
							Du siehst das, dass Herausfinden von Keywords in den Suchmaschinen kein Hexenwerk ist. Mit der richtigen
							Strategie und der richtigen Agentur kannst Du diese Keywords schnell herausfinden. Gehe am besten jetzt
							noch zu Google und gebe mal die wichtigsten Keywords ein und analysiere, wer die Konkurrenz im Netz ist.
						</P>
					</div>
					<div className="p-3 bg-pattern md:order-1">
						<StaticImage
							src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_das-richtige-keyword.jpg"
							alt="SEO Agentur Kassel Suche | Tastatur mit Suchen Tasten"
							title="SEO Agentur Kassel Suche | Tastatur mit Suchen Tasten"
							placeholder="blurred"
							aspectRatio={16 / 8}
							transformOptions={{ cropFocus: "north" }}
						/>
					</div>
				</Grid>
			</Container> */}
			{/* <Container>
				<Grid cols={2} itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Externe Empfehlungen" number="05" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Wie gute Backlinks Dir helfen
						</H2>
						<P>
							Genauso wie wichtig wie das Netzwerken in Kassel ist, so sind Backlinks einer der wichtigsten Faktoren für
							ein gutes Suchmaschinenranking für das Netzwerk von Google. Es ist erwiesen, dass Backlinks den Suchenden
							helfen, relevante Inhalte leichter zu finden. Backlinks werden von Google als eine Empfehlung genutzt und
							sie bestimmen die Wichtigkeit Deines Inhalts, wenn es um das Ranking in den SERPS geht. Wenn Du die
							Bedeutung von Backlinks verstehst, solltest Du Dir bewusst machen, dass nicht alle Links gleich sind. Es
							gibt zwei Arten: dofollow und nofollow. DoFollow Links haben einen signifikanten Einfluss auf Dein
							Ranking, während NoFollow Links es nur indirekt beeinflussen, indem sie Deinen „Link-Mix“ verbessern. Ein
							Verhältnis von 20%-50% wird für einen gesunden Link empfohlen. Eine gute Offpage-Optimierung hilft Dir
							dabei, diese Links aufzubauen. Die richtige SEO Agentur hilft Dir dabei auch in diesem Fällen weiter und
							baut eine Strategie auf um Dein Linknetzwerk aus zubauen.
						</P>
					</div>
					<div className="p-3 bg-pattern">
						<StaticImage
							src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_backlinks.jpg"
							alt="SEO Agentur Kassel OffPage | Block mit dem Wort Linkbuilding"
							title="SEO Agentur Kassel OffPage | Block mit dem Wort Linkbuilding"
							placeholder="blurred"
							aspectRatio={16 / 8}
						/>
					</div>
				</Grid>
			</Container> */}
			{/* <Container>
				<Grid cols={2} itemsCenter>
					<div className="md:order-2">
						<Divider className="mb-4" />
						<NumberedSection text="Lokale Sichtbarkeit" number="06" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Lokales SEO als Marketing-Strategie für die örtliche Sichtbarkeit in Kassel
						</H2>
						<P className="mb-3">
							Lokales SEO hilft Nutzern dabei, lokale Unternehmen in ihrer Region, wie zum Beispiel in Kassel, zu
							finden. Es gibt eine Reihe von Dingen, die Du tun kannst, um die Sichtbarkeit Deines Unternehmens in
							Suchmaschinen zu erhöhen. Der Aufbau einer Google My Business Seite ist eine Möglichkeit, da sie Deinem
							Unternehmen hilft, in den lokalen Suchmaschinenergebnissen zu ranken. Außerdem können Backlinks von
							anderen Unternehmen in Deiner Region und Einträge auf Plätzen wie Yelp auch bei Kundenanfragen oder der
							Sichtbarkeit Deines Unternehmens helfen.
						</P>
						<P>
							Ein guter SEO kennt die Kniffe Deiner Stadt und weiß wie man den Besucher neben dem üblichen Marketing
							voll automatisch in Dein Unternehmen bringt. Wir sind davon überzeugt davon, dass wir gute Arbeit in der
							Suchmaschinenoptimierung machen und Dir dabei helfen können, die richtigen SEO-Maßnahmen bei Dir
							anzustoßen, sodass ein vollautomatischer Strom an Kunden bei Dir auftaucht. Hoffentlich konnten wir Dich
							davon überzeugen, dass wir Experten für SEO sind und Du die richtige Agentur für Kassel gefunden hast.
							Suchmaschinenoptimierung ist unser Handwerk und Du musst Dich darauf verlassen können, dass Dein SEO
							funktioniert und die Kunden Dich bei Google finden. SEO gehört in unseren Augen in jede
							Marketing-Strategie.
						</P>
					</div>
					<div className="p-3 bg-pattern md:order-1">
						<StaticImage
							src="../../../img/pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_lokal-seo.jpg"
							alt="SEO Agentur Kassel Local | Cafébesitzer vor der Bar"
							title="SEO Agentur Kassel Local | Cafébesitzer vor der Bar"
							placeholder="blurred"
							aspectRatio={16 / 8}
						/>
					</div>
				</Grid>
			</Container> */}
			{/* <About link={`${location.pathname}anfragen/`} keyword={keyword} /> */}
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_sep-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}

		imageHeaderSearchConsole: file(
			relativePath: { eq: "pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_header-search-console.png" }
		) {
			childImageSharp {
				gatsbyImageData(quality: 80, width: 672)
			}
		}

		imageHeaderAndreas: file(
			relativePath: { eq: "pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_header-andreas.png" }
		) {
			childImageSharp {
				gatsbyImageData(quality: 80, width: 384)
			}
		}

		imageSeoVergleich: file(
			relativePath: { eq: "pages/leistungen/seo-agentur-kassel/seo-agentur-kassel_seo-vs-sea.png" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
